import React, { useEffect } from "react";
import { Link } from "../links";
import "./footer.scss";
import { navLinks } from "../../data/components/header";
import useModal from "../../lib/useModal";
import {
  legalDisclaimer,
  termsAndConditions,
  termsAndConditionsP2,
} from "../../data/components/footer";
import { StaticImage } from "gatsby-plugin-image";
import classNames from "classnames";

interface FooterProps {
  pathname: string;
}

export const ModalContent = (
  <div className="legal-disclaimer">
    <h3>Legal Disclaimer:</h3>
    <p>{legalDisclaimer}</p>
  </div>
);
const Footer: React.FC<FooterProps> = ({ pathname }) => {
  const { openModal } = useModal();

  return (
    <div className="pcm-footer">
      <div className="pcm-footer__inner">
        <div className="pcm-footer__upper">
          <div className="pcm-footer__upper__image">
            <StaticImage src="../../images/pcmWhite.png" alt="PCM white logo" />
          </div>
          <div className="pcm-footer__upper__links">
            {navLinks.map((item) => {
              const { title, url } = item;
              const menuLinkClasses = classNames(
                "pcm-footer__upper__links__link",
                {
                  "pcm-footer__upper__links__link--active": pathname === url,
                }
              );
              return (
                <Link key={title} to={url}>
                  <div className={menuLinkClasses}>
                    <p>{title}</p>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
        <div className="pcm-footer__lower">
          <div className="pcm-footer__lower__tm">
            <p>© 2024 Preservation Capital Management.&nbsp;</p>
            <p>All rights reserved.</p>
          </div>
          <div className="pcm-footer__lower__links">
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/terms-and-conditions">Terms and Conditions</Link>
            <p onClick={() => openModal(ModalContent)}>Legal Disclaimer</p>
            <Link
              isOutbound
              to="https://files.adviserinfo.sec.gov/IAPD/Content/Common/crd_iapd_Brochure.aspx?BRCHR_VRSN_ID=854347"
            >
              Form ADV Part 2A
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
