import React, { useState, useEffect, useRef } from "react";
import { seoDataObj } from "../data/components/seo";
import withLocation from "../lib/withLocation";
import gsap from "gsap";
import { Link } from "../components/links";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "./main-layout.scss";
import { SEO } from "../components/seo";
import { ReactContext } from "../context/reactContext";
import classNames from "classnames";
import Lenis from "@studio-freight/lenis";
import { StaticImage } from "gatsby-plugin-image";
import { useCheckScreenSize } from "../lib/checkScreenSize";
import { MainLayoutProps } from "./types";
gsap.registerPlugin(ScrollTrigger);

const LandingPageLayout: React.FC<MainLayoutProps> = ({
  children,
  location,
  title = "",
  description = "",
  meta = [],
  layout,
}) => {
  const { pathname } = location;

  const [modalComponent, setModalComponent] = useState<React.ReactNode | null>(
    null
  );
  const [menuActive, setMenuActive] = useState(false);
  const [showFloatingButton, setShowFloatingButton] = useState(false);

  const headerRef = useRef<HTMLElement>(null);
  const floatingButtonRef = useRef(null);

  const isTablet = useCheckScreenSize(1200);

  const hamburgerClassses = classNames("floating-nav__button", {
    "floating-nav__button--active": menuActive,
  });
  const floatingNavClasses = classNames("floating-nav", {
    "floating-nav--active": showFloatingButton && !menuActive,
    "floating-nav--active--open": showFloatingButton && menuActive,
  });
  const floatingLogoClasses = classNames("pcm-floating-logo", {
    "pcm-floating-logo--active": showFloatingButton,
  });

  const overlayClasses = classNames("nav-overlay", {
    "nav-overlay--active": menuActive,
  });

  useEffect(() => {
    const lenis = new Lenis({
      lerp: 0.1,
      duration: 1,
      wheelMultiplier: 1,
      touchMultiplier: 2,
      smoothWheel: true,
    });
    function raf(time: number) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }
    const rafId = requestAnimationFrame(raf);

    return () => {
      (lenis as any).off("scroll");
      cancelAnimationFrame(rafId);
    };
  }, [menuActive]);

  useEffect(() => {
    if (isTablet && headerRef.current) {
      gsap.set(headerRef.current, {
        y: -headerRef.current.offsetHeight,
      });
      setShowFloatingButton(true);
      return;
    }

    if (headerRef.current && !isTablet) {
      const header = headerRef.current;
      ScrollTrigger.create({
        start: "top+=150 top",
        end: "bottom bottom",
        trigger: headerRef.current,
        onEnter: () => {
          gsap.to(headerRef.current, {
            y: -header.offsetHeight,
            duration: 0.1,
            delay: 0.25,
            ease: "power1.inOut",
            onComplete: () => setShowFloatingButton(true),
          });
        },
      });

      ScrollTrigger.create({
        start: "top+=100 top",
        end: "bottom bottom",
        trigger: headerRef.current,
        onLeaveBack: () => {
          gsap.to(headerRef.current, {
            y: 0,
            duration: 0.25,
            ease: "power1.inOut",
            onComplete: () => {
              setShowFloatingButton(false);
              setMenuActive(false);
            },
          });
        },
      });
    }
  }, [setShowFloatingButton, setMenuActive, isTablet]);

  const safePathname = pathname || "/";

  const seoData = seoDataObj[safePathname] ||
    seoDataObj[safePathname.slice(0, -1)] || {
      title: "Preservation Capital Management",
      description:
        "Desigingn and delivering low-cost, innovative investment solutions for the clients of financial advisors that make investing and staying invested less stressful by structuring and communicating explicit loss limits while still providing investors with competitive long-term returns.",
      meta: [],
    };

  return (
    <ReactContext.Provider
      value={{
        menuActive,
        setMenuActive,
        modalComponent,
        setModalComponent,
      }}
    >
      <div className={`pcm-site`}>
        {seoData && <SEO {...seoData} />}
        <Header ref={headerRef} pathname={safePathname} />
        <Link to="/" className={floatingLogoClasses}>
          <StaticImage src="../images/pcmTree.png" alt="PCMLogoWhite" />
        </Link>
        <div
          ref={floatingButtonRef}
          className={floatingNavClasses}
          onClick={() => setMenuActive(!menuActive)}
        >
          <button
            onClick={() => setMenuActive(!menuActive)}
            className={hamburgerClassses}
          >
            <span />
            <span />
            <span />
          </button>
        </div>
        <div className="pcm-main">{children}</div>
        <Footer pathname={safePathname} />
      </div>
      {modalComponent}
      {/* <GlobalCursor pathname={pathname}/> */}
      <span className={overlayClasses}></span>
    </ReactContext.Provider>
  );
};

export default withLocation(LandingPageLayout);
