import "./src/styles/global.scss";
import packageInfo from "./package.json";

if (typeof window !== "undefined") {
  window.portfolio = window.portfolio || {};
  window.portfolio.version = packageInfo.version;
}

export const shouldUpdateScroll = ({ routerProps, getSavedScrollPosition }) => {
  const { location } = routerProps;

  if (location.hash) {
    return false;
  }

  if (routerProps.location.action === "PUSH") {
    window.scrollTo(0, 0);
  } else {
    const savedPosition = getSavedScrollPosition(routerProps.location);
    window.scrollTo(...(savedPosition || [0, 0]));
  }

  return false;
};
