interface SeoData {
  description: string;
  title: string;
  image?: string;
}

interface SeoDataObject {
  [path: string]: SeoData;
}

export const seoDataObj: SeoDataObject = {
  "": {
    description:
      "Pioneering retirement investment solutions. Expertly crafted for income security and growth. Discover our unique, low-cost approach to safeguard your future.",
    title: "Preservation Capital Management",
    image:
      "https://s3.us-west-1.amazonaws.com/assets.preservationcap.com/social-general.png",
  },
  "/about": {
    description:
      "Join Preservation Capital, led by industry experts Jerry Chafkin and Matt Connors, for secure retirement solutions. Our approach prioritizes income growth and safety.",
    title: "About Us",
    image:
      "https://s3.us-west-1.amazonaws.com/assets.preservationcap.com/social-general.png",
  },
  "/strategy": {
    description:
      "Innovative, low-cost investment strategies for enhanced retirement income by Preservation Capital. Focused on long-term returns with explicit loss limits.",
    title: "Our Strategy",
    image:
      "https://s3.us-west-1.amazonaws.com/assets.preservationcap.com/social-general.png",
  },
};
