interface LinkItem {
  title: string,
  url: string,
}

export const navLinks: LinkItem[] = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "About Us",
    url: "/about",
  },
  {
    title: "Our Strategy",
    url: "/strategy",
  },
  {
    title: "Contact",
    url: "mailto:jerry.chafkin@preservationcap.com,matt.connors@preservationcap.com",
  },
];

export const aboutAnchorLinks: LinkItem[] = [
  {
    title: "Our Story",
    url: "#pcm-about-our-story",
  },
  {
    title: "Our People",
    url: "#pcm-about-team",
  },
  {
    title: "Our Mission",
    url: "#pcm-about-our-mission",
  },
  {
    title: "Risk Philosophy",
    url: "#pcm-about-philosophy",
  },
];


export const strategyAnchorLinks: LinkItem[] = [
  {
    title: "Maximizing Investment",
    url: "#pcm-maximize",
  },
  {
    title: "Compare",
    url: "#pcm-strategy__accumulators",
  },
  {
    title: "Objectives",
    url: "#pcm-objectives",
  },
  {
    title: "Predictability and Income",
    url: "#pcm-strategy__predictability",
  },
  {
    title: "Peace of Mind",
    url: "#pcm-strategy__peace",
  },
  {
    title: "Keeping Pace with Inflation",
    url: "#pcm-strategy__keeping-pace",
  },
  {
    title: "Control and Flexibility",
    url: "#pcm-strategy__control",
  },
];
