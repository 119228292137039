import classNames from "classnames";
import React, { ReactNode, MouseEvent } from "react";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

interface DynamicLinkProps {
  ariaLabel?: string;
  to: string;
  label?: string;
  className?: string;
  children?: ReactNode;
  isOutbound?: boolean;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
  page?: string;
  section?: string;
  subsection?: string;
  target?: React.HTMLAttributeAnchorTarget;
  contains?: string;
  isCTA?: boolean | string;
  isAnchor?: boolean;
  download?: boolean;
  linkId?: string;
  disable?: boolean;
  [x: string]: any; // for rest properties
}

const DynamicLink: React.FC<DynamicLinkProps> = ({
  ariaLabel = "",
  to,
  label,
  className = "",
  children,
  isOutbound,
  onClick = () => {},
  page = "",
  section = "",
  subsection = "",
  target = null,
  contains = "",
  isCTA = false,
  isAnchor = false,
  download = false,
  linkId = "",
  disable = false,
  ...rest
}) => {
  if (disable) {
    return (
      <a className={className} id={linkId} aria-label={ariaLabel}>
        {children}
      </a>
    );
  }
  const isOut =
    typeof isOutbound === "boolean"
      ? isOutbound
      : /^http.+$|^mailto/.test(to) && !/www\.preservationcap\.dev/.test(to);

  const _ariaLabel =
    typeof children === "string" && !ariaLabel ? children : ariaLabel;
  if (!isOut && !target && !isAnchor) {
    return (
      <Link
        id={linkId}
        aria-label={_ariaLabel}
        to={to}
        className={className}
        download={download}
        onClick={onClick}
        {...rest}
      >
        {children}
      </Link>
    );
  } else if (isAnchor) {
    return (
      <AnchorLink
        aria-label={_ariaLabel}
        to={to}
        className={className}
      >
        {children}
      </AnchorLink>
    );
  }

  const linkClasses = classNames("outboundlink", className);

  return (
    <a
      aria-label={_ariaLabel}
      href={to}
      target={isOut ? "_blank" : target || undefined}
      className={linkClasses}
      download={download}
      id={linkId}
      rel="noreferrer noopener"
      onClick={onClick}
      {...rest}
    >
      {children}
    </a>
  );
};

export default DynamicLink;
