import React, {
  useRef,
  useState,
  useEffect,
  ForwardedRef,
  useContext,
} from "react";
import { Link } from "../links";
import classNames from "classnames";
import {
  navLinks,
  aboutAnchorLinks,
  strategyAnchorLinks,
} from "../../data/components/header";
import { useLocation } from "@reach/router";
import { ReactContext, ReactContextType } from "../../context/reactContext";
import "./header.scss";
import { AnchorLinksMap, HeaderProps, AnchorLinksTitleMap } from "./types";
import { StaticImage } from "gatsby-plugin-image";

const Header = React.forwardRef<HTMLElement, HeaderProps>(
  ({ pathname = "/" }, ref) => {
    const [linksMenuOpen, setLinksMenuOpen] = useState(false);
    const { menuActive, setMenuActive } =
      useContext<ReactContextType>(ReactContext);
    const linksRef = useRef<HTMLDivElement>(null);

    const anchorLinksMap: AnchorLinksMap = {
      "/about": aboutAnchorLinks,
      "/strategy": strategyAnchorLinks,
    };

    const anchorLinksTitleMap: AnchorLinksTitleMap = {
      "/about": "About Us",
      "/strategy": "Our Strategy",
    };

    const rootPath =
      pathname !== "/" ? pathname.split("#")[0].replace(/\/$/, "") : "/";
      
    const linksClasses = classNames("pcm-header__links", {
      "pcm-header__links--open": linksMenuOpen,
    });
    const linksIndexClasses = classNames("pcm-header__links__index", {
      "pcm-header__links__index--open": linksMenuOpen,
    });

    const mobileNavClasses = classNames("pcm-header__mobile-nav", {
      "pcm-header__mobile-nav--active": menuActive,
      "pcm-header__mobile-nav--active--page": menuActive && anchorLinksMap[rootPath],
    });


    return (
      <header ref={ref} className="pcm-header">
        <div className="pcm-header__inner">
          {!menuActive && (
            <Link className="pcm-header__logo" to="/">
              <StaticImage src="../../images/pcmWhite.png" alt="PCM Logo" />
            </Link>
          )}
          <div ref={linksRef} className={linksClasses}>
            <div className="pcm-header__links__inner">
              {linksMenuOpen && (
                <div className="pcm-header__links__menu-logo">
                  <StaticImage
                    src="../../images/pcmWhite.png"
                    alt="PCM Logo White"
                  />
                </div>
              )}
              <div className={linksIndexClasses}>
                {navLinks.map((item) => {
                  const { title, url } = item;
                  const navLinkClasses = classNames("pcm-header__nav-link", {
                    "pcm-header__nav-link--active": rootPath === url,
                  });
                  return (
                    <div
                      key={title}
                      onClick={() => {
                        if (menuActive) {
                          setMenuActive(false);
                        }
                      }}
                      className={navLinkClasses}
                    >
                      <Link to={url}>{title}</Link>
                    </div>
                  );
                })}
              </div>
              <div className={mobileNavClasses}>
                <h5>Navigation</h5>
                {navLinks.map((item) => {
                  const { title, url } = item;
                  const navLinkClasses = classNames(
                    "pcm-header__mobile-nav__link",
                    {
                      "pcm-header__mobile-nav__link--active": rootPath === url,
                    }
                  );
                  return (
                    <div
                      key={title}
                      onClick={() => {
                        if (menuActive) {
                          setMenuActive(false);
                        }
                      }}
                      className={navLinkClasses}
                    >
                      <Link to={url}>{title}</Link>
                    </div>
                  );
                })}
                {anchorLinksMap[rootPath] && (
                  <div className="pcm-header__mobile-nav__anchors">
                    <h5>{anchorLinksTitleMap[rootPath]}</h5>
                    {anchorLinksMap[rootPath].map((item) => {
                      const { title, url } = item;
                      return (
                        <div
                          key={title}
                          onClick={() => {
                            if (menuActive) {
                              setMenuActive(false);
                            }
                          }}
                          className="pcm-header__mobile-nav__anchors__link"
                        >
                          <Link isAnchor to={url}>
                            {title}
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
);

export default Header;
