interface TACP2 {
  title?: string;
  text: string;
}

export const legalDisclaimer: string =
  "Preservation Capital Management does not generally make its strategy directly available to individual or retail investors. Preservation Capital Management serves other investment advisers and institutions who understand the risks and benefits of writing covered call options, buying put options, use of margin, and serve their own clients seeking income in retirement without sacrificing growth while hedging certain risks.  Advisers and institutions should perform their own analysis to take into account their specific clients' needs and factors such as the adviser's own management of a client's overall portfolio and the probability of various events and outcomes.  Please note that you should not assume that recommendations made in the future will be profitable or equal the performance of the securities and strategy discussed on this website, which is intended for information purposes only.";

export const termsAndConditions: string =
  "This website contains information about our asset management services and portfolio strategies and may even mention specific investments.  It is generally intended for an institutional audience. By accessing the information on this website, you understand and agree that the content does not constitute investment advice or investing recommendations either generally or for you in particular.  For more information about our investment management services, you agree to contact us directly.  To learn more about us and our services and whether they may be right for you, please talk to your primary investment adviser and see our ADV Part 2A disclosure brochure below.";

export const termsAndConditionsP2: TACP2[] = [
  {
    title: "The Nature of the Website and Our Responsibility",
    text: "We provide this Website as a public source for general information about our firm and our approach to investing. While we use reasonable efforts to include accurate up-to-date information on the Website, we make no warranties or representations as to its accuracy and the information is provided “as is.”",
  },
  {
    text: "We assume no liability or responsibility for any errors or omissions in the content on the Website. The use and browsing of the Website are at your own risk. The Firm assumes no liability for any direct, incidental, consequential, indirect, punitive, or any other damages arising out of your access to or use of the Website.",
  },
  {
    title: "Third Party Links",
    text: "We may include links on this Website that lead to other websites, resources, or tools not operated or maintained by our firm. A link to another website, resource, or tool is not an endorsement of it or its content. We do not control such websites and are not responsible for their content. Any views expressed by any third parties are the views of the applicable author and do not necessarily align with our views. We have no control over, and are not responsible for, your interactions with any third parties.",
  },
  {
    title: "Intellectual Property Rights",
    text: "Nothing on this Website shall be construed as conferring any license or intellectual property right, including any right in the nature of trademark or copyright of Preservation Capital Management, or any third party, whether by estoppel, implication, or otherwise. All trademarks and trade names are the property of their respective owners. Except as otherwise noted, Retirement Income Advisors LLC d/b/a Preservation Capital Management is the owner of the trademarks and service marks on this Website and the owner of the  copyright on all original text, analysis, and content on the website, whether registered or not. Copyrighted content may be used only with express written permission.  All rights not expressly granted herein are reserved exclusively and entirely to Retirement Income Advisors LLC.",
  },
  {
    text: "By accessing this Website, you agree to these terms and conditions.",
  },
];
