import React from "react";
import MainLayout from "./main-layout";
import { LayoutProps } from "./types";

const Layout: React.FC<LayoutProps> = ({ children, pageContext }) => {
  switch (pageContext.layout) {
    default:
      return <MainLayout {...pageContext}>{children}</MainLayout>;
  }
};

export default Layout;
