import React, { useContext } from "react";
import { ReactContext } from "../../context/reactContext";
import classNames from "classnames";
import "./modal.scss";

const useModal = () => {
  const context = useContext(ReactContext);
  if (context === undefined) {
    throw new Error("useModal must be used within a ReactContext provider");
  }

  const { setModalComponent } = context;
  const closeModal = () => {
    setModalComponent(null);
  };

  const openModal = (
    ModalComponent: React.ReactNode,
    background: boolean = false
  ) => {
    const innerClasses = classNames("modal-container__inner", {
      "modal-container__inner--background": background,
    });

    setModalComponent(
      <div
        className="modal-container"
        onClick={() => closeModal()}
      >
        <div className={innerClasses}>{ModalComponent}</div>
      </div>
    );
  };


  return { openModal, closeModal };
};

export default useModal;
